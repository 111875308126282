<template>
    <div>
        <div v-if="loaded">
            <transition name="fade">
                <div>
                    <label for="diffInput"><input v-model="diff" id="diffInput" type="number"></label>
                    <select v-model="t" v-if="loaded">
                        <option value="second">Second</option>
                        <option value="minute">Minute</option>
                        <option value="hour">Hour</option>
                        <option value="day">Day</option>
                    </select>
                    <button @click="chart">redraw</button>
                    <line-chart
                    
                    :chart-data="chartData"
                    :options="options"
                    />
                </div>
            </transition>
            
        </div>
        <p v-if="!loaded">loading chart</p>
    </div>
</template>

<script>
import LineChart from './LineChart.vue'

export default {
    components: {LineChart},

    data: () => ({
        chartData: null,
        options: {
                legend: {
                    display: false
                },

                scales: {
                    xAxes: [{
                        type: 'time',
                        distribution: 'series',
                        offset: false,
                      

                    }],
                    yAxes: [{
                        type: 'linear',
                        ticks:{
                        beginAtZero: true,
                        },  

                    }]
                },

                maintainAspectRatio: false,
            },

        loaded: null,

        n: 100,
        t: "minute",
        diff: 15,
    }),

    async mounted() {
        this.loaded=false
        this.chart()

    },

    methods: {
        async chart() {
            try {
            let response = await fetch ('/api/graph?' + new URLSearchParams({
                n: this.n,
                t: this.t,
                diff: this.diff,
            }))
            let datas = await response.json()
            this.chartData = {
                datasets: [
                    {
                        label: "test",
                        data: datas
                    }
                ]
            }
            // console.log(this.chartData,this.chartData.dataset.data)
            this.loaded=true
        } catch (e) {
            console.error(e)
        }
        
    },
    
    }
}
</script>

<style>

</style>